<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title fs25">Menu Addons</div>
          <button
            v-if="isAuthorized(permissions.menu_addon_create)"
            class="btn btn-sm btn-primary p-2 btn-resize-standard"
            @click="handleActionToggle"
          >
            <i class="icon-add"/>Add New
          </button>
        </div>
        <div class="card-body" v-loading="state.loading">

          <!--list-->
          <ItemTable
            :data="state.addons"
            :columns="state.columnMap"
            :show-actions="isAuthorized(permissionsGroup.addons)"
          >
            <template #price="{item}">
              <span>{{ parseFloat(item.price).toFixed(2) }}</span>
            </template>

            <template #type="{item}">
              <span v-html="renderType(item.type)"/>
            </template>

            <template #status="{item}">
              <span v-html="renderBadge(item.status)"/>
            </template>

            <template #action="{item}" v-if="isAuthorized(permissionsGroup.addons)">
              <EditButton
                v-if="isAuthorized(permissions.menu_addon_update)"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_UPDATE)"
              />

              <DeleteButton
                v-if="isAuthorized(permissions.menu_addon_delete)"
                :loading="item.id === state.action.subject?.id && state.action.deleteLoading"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_DELETE)"
              />
            </template>

          </ItemTable>

          <!--Add Modal-->
          <AddonAction
            :show="state.showAction"
            :addon="state.action.subject"
            :updating="state.action.types.edit"
            @hide="handleActionToggle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import { constants } from '@/utils';
import Toaster from '@/utils/Toaster';
import AddonAction from '@/components/Menu/AddonAction';
import permissions, { permissionsGroup } from '@/utils/permissions';
import { fetchDataIfNotExist, isAuthorized, renderEnabledBadge } from '@/utils/Helper';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default {
  name: 'Addons',
  components: { DeleteButton, EditButton, AddonAction, ItemTable, ButtonComp },
  setup () {

    const store = useStore();
    const addonStore = store.state.menu.addons;

    const state = reactive({
      showAction: false,

      loading: false,

      addons: [],
      columnMap: [
        { field: 'name', header: 'Name' },
        { field: 'price', header: 'Price', sortable: true },
        { field: 'sort', header: 'Sort', sortable: true },
        { field: 'type', header: 'Type', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
      ],

      action: {
        types: {
          status: false,
          edit: false,
          delete: false
        },
        subject: false,
        deleteLoading: false
      },
    });

    watch(addonStore, (nv) => {
      state.addons = nv.data?.addons || [];
    });

    onMounted(() => {

      fetchDataIfNotExist(addonStore.data, async () => {
        state.loading = true;
        await store.dispatch('menu/fetchAddons');
        state.loading = false;
      }, () => {
        state.addons = addonStore.data.addons;
      });

    });

    const renderBadge = (status) => {
      return renderEnabledBadge(status);
    };

    const renderType = (addonType) => {

      if (addonType === 'item') {
        return '<span class="badge table-badge badge-dark badge-pill">item</span>';
      }

      return addonType;
    };

    const handleActionToggle = (value) => {

      // set the desired action or just toggle
      const newValue = !!value || !state.showAction;

      state.showAction = newValue;

      // if closing the action then make edit false
      if (!newValue) {
        state.action.types.edit = false;
        state.action.subject = false;
      }

    };

    const handleDelete = (item) => {

      SweetAlert.confirmError({
        text: `This will permanently Delete '${item.name}'`,
        preConfirm: async (status) => {

        if (!status) return;

        state.action.deleteLoading = true;

        try {

          await store.dispatch('menu/deleteAddon', item.id);

          Toaster.successAlt({
            title: 'Deleted',
            message: `'${item.name}' has been deleted`
          });

        } catch (e) {
          Toaster.error({
            title: 'Deletion Failed',
            message: e.message
          });
        }

        state.action.deleteLoading = false;

      }
      });


    };

    const handleAction = (item, actionType) => {

      state.action.subject = item;

      switch (actionType) {
        case constants.LIST_ITEM_ACTION_UPDATE:
          if (!isAuthorized(permissions.menu_addon_update)) return;
          state.action.types.edit = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_UPDATE_STATUS:
          state.action.types.delete = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_DELETE:
          if (!isAuthorized(permissions.menu_addon_delete)) return;
          state.action.types.delete = true;
          handleDelete(item);
          break;
      }

    };

    return {
      state,
      constants,
      handleAction,
      renderBadge,
      renderType,
      handleActionToggle,
      permissionsGroup,
      permissions,
      isAuthorized
    };
  }
};
</script>
