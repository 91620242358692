<template>
  <ModalComp
    :title-text="state.modal.title"
    title-icon-class="icon-layers2"
    :loading="state.form.submitting"
    :show="state.modal.show"
    :mode="updating ? 'update' : 'create'"
    :ok-button-text="state.modal.submitBtnText"
    @hide="handleClose"
    @submit="handleSubmit"
  >

    <form class="row">
      <div class="form-group col-12">
        <label for="addonName" class="col-form-label">
          Addon Name:
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="addonName"
          placeholder="Addon name"
          v-model.trim="state.form.addonName.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.addonName.error" />
      </div>

      <div class="form-group col-6">
        <label for="price" class="col-form-label">
          Price:
          <span class="text-danger">*</span>
        </label>
        <input
          type="number"
          step="0.01"
          class="form-control"
          id="price"
          placeholder="Price"
          v-model.number="state.form.price.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.price.error" />
      </div>

      <div class="form-group col-6">
        <label for="sort" class="col-form-label">
          Sort:
          <span class="text-danger">*</span>
        </label>
        <FormInputHelp>Works on descending order</FormInputHelp>
        <input
          type="number"
          class="form-control"
          id="sort"
          placeholder="Sort position (descending)"
          v-model.number="state.form.sort.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.sort.error" />
      </div>

      <div
        class="form-group col-6"
      >
        <!--suppress XmlInvalidId -->
        <label for="type" class="col-form-label">
          Type:
        </label>
        <FormInputHelp>Select a type that the food belongs to</FormInputHelp>
        <TreeSelect
          id="type"
          placeholder="Select a type"
          v-model="state.form.typeSelect.value"
          :options="state.form.typeSelect.options"
          :clearable="false"
          :searchable="false"
        />
        <FormErrorMsg :error="state.form.typeSelect.error" />
      </div>

      <div class="form-group col-6">
        <label for="sort" class="col-form-label">
          Enable Addon:
          <span class="text-danger">*</span>
        </label>
        <FormInputHelp>Disabling will hide the addon from users</FormInputHelp>
        <div>
          <SwitchComp
              :checked="state.form.status"
              enable-text="Enabled"
              disable-text="Disabled"
              @change="evt=>state.form.status=evt"
            />
        </div>
      </div>
    </form>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, minValue, required, decimal } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import Toaster from '@/utils/Toaster';
import { mustContain } from '@/utils/CustomValidators';

export default {
  name: 'AddonAction',
  components: { ModalComp, FormErrorMsg, FormInputHelp, SwitchComp, TreeSelect },
  emits: ['hide'],
  props: {
    addon: {
      type: [Object, Boolean],
      default: false
    },
    updating: Boolean,
    show: {
      type: Boolean,
      default: false
    },

  },

  setup (props, { emit }) {

    const store = useStore();

    const addonState = store.state.menu.addons;

    const state = reactive({
      modal: {
        show: false,
        title: 'Create New Addon',
        submitBtnText: 'Add Addon'
      },
      form: {
        addonName: {
          error: false,
          value: ''
        },
        price: {
          error: false,
          value: 0.01
        },
        sort: {
          error: false,
          value: 0
        },
        status: true,
        typeSelect: {
          value: 'item',
          options: [],
          error: false
        },
        submitting: false
      },
    });

    state.form.typeSelect.options = computed(() => {
      const list = addonState.data.addonTypes;
      if (!list) return [];

      return list.map(typeName => ({
        id: typeName,
        label: typeName
      }));
    });

    watch(() => props.show, (nv) => {
      state.modal.show = nv;
    });

    // customize modal for update &
    // reset form when closed
    watch(() => props.updating, (nv) => {

      // reset the form if updating
      if (!nv) {
        // reset form
        resetForm();

        state.modal = {
          ...state.modal,
          title: 'Create New Addon',
          submitBtnText: 'Add Addon'
        };

        return;
      }

      // or customize modal
      state.modal = {
        ...state.modal,
        title: 'Update Addon',
        submitBtnText: 'Save Changes'
      };

      // populate values
      state.form.addonName.value = props.addon.name;
      state.form.sort.value = props.addon.sort;
      state.form.typeSelect.value = props.addon.type;
      state.form.status = props.addon.status;
      state.form.price.value = props.addon.price;
    });

    // validation start
    const validationRules = {
      form: {
        addonName: {
          value: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50),
          }
        },
        price: {
          value: {
            required,
            decimal,
            mustContain: mustContain('.')
          }
        },
        typeSelect: {
          value: {
            required,
            minLength: minLength(1)
          }
        },
        sort: {
          value: {
            required,
            minValue: minValue(0)
          }
        }
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.addonName.error = errors['form.addonName.value'] || false;
          state.form.price.error = errors['form.price.value'] || false;
          state.form.typeSelect.error = errors['form.typeSelect.value'] || false;
          state.form.sort.error = errors['form.sort.value'] || false;
        },
        {
          'form.addonName.value': 'Addon Name',
          'form.price.value': 'Price',
          'form.typeSelect.value': 'Type',
          'form.sort.value': 'Sort',
        }
      );
    });
    // validation end

    const resetForm = () => {
      state.form.addonName.value = '';
      state.form.price.value = '';
      state.form.sort.value = 0;
      state.form.typeSelect.value = null;
      state.form.status = true;
    };

    function handleClose () {
      emit('hide', false);
    }

    async function handleSubmit () {

      if (!ErrorHelper.checkValidity(validator)) return;

      const data = {
        addonName: state.form.addonName.value,
        price: parseFloat(state.form.price.value).toFixed(2),
        type: state.form.typeSelect.value,
        sort: state.form.sort.value,
        status: state.form.status ? 1 : 0,
      };

      state.form.submitting = true;

      try {

        if (props.updating) {
          await store.dispatch('menu/updateAddon', { id: props.addon.id, data });
        } else {
          await store.dispatch('menu/createAddon', data);
        }

        // close the modal
        handleClose();

        resetForm();

        Toaster.successAlt({
          title: `Addon ${props.updating ? 'Updated' : 'Created'}`,
          message: `'${data.addonName}' has been ${props.updating ? 'updated' : 'created'}`
        });

      } catch (e) {

        Toaster.error({
          title: 'Failed',
          message: e.message
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.addonName.error = ex(err.addonName);
        });

      }

      state.form.submitting = false;
    }

    return {
      state,
      handleClose,
      handleSubmit,
    };
  }
};
</script>
